import { Component, Input, OnChanges } from '@angular/core';
import { Question } from '../../../models/webform';
import { SectionOption, ArithmeticTypes } from '../../../models/form';

@Component({
	selector: 'app-arithmetic',
	templateUrl: './arithmetic.component.html',
	styleUrls: [ './arithmetic.component.css' ]
})
export class ArithmeticComponent implements OnChanges {
	@Input('question') question: Question | undefined;
	@Input('formViewType') formViewType: string;

	answer: number;

	ngOnChanges() {
		if (!this.question) return;

		this.answer = this.question.answer[0];
	}

	calculateResult() {
		if (!this.question) return;

		this.question.answer = [];
		this.answer = null;

		let valid: boolean = true;

		this.question.option.forEach((option: SectionOption) => {
			if (!option.answer || Number(option.answer) < option.validation.min || Number(option.answer) > option.validation.max)
				valid = false;
		});

		if (!valid) return;

		this.question.option.forEach((option: SectionOption, index: number) => {
			if (0 === index || !option.answer) return;

			if (1 === index) this.answer = +this.question.option[0].answer;

			switch (this.question.option[index - 1].arithmeticFieldType.arithmeticOperation) {
				case ArithmeticTypes.DIVIDE:
					if (isFinite(this.answer / (option.answer as any)))
						this.answer = this.answer / (option.answer as any);
					break;
				case ArithmeticTypes.MULTIPLY:
					if (isFinite(this.answer * (option.answer as any)))
						this.answer = this.answer * (option.answer as any);
					break;
				case ArithmeticTypes.ADD:
					if (isFinite(this.answer + (option.answer as any)))
						this.answer = this.answer + (option.answer as any);
					break;
				case ArithmeticTypes.SUBTRACT:
					this.answer = this.answer - (option.answer as any);
					break;
				default:
					return;
			}
		});

		if (this.answer) this.answer = +this.answer.toFixed(2);

		if (this.answer !== undefined) {
			this.question.answer[0] = this.answer;
			this.question.valid = true;
		} else {
			this.question.valid = false;
		}
	}
}
