<div class="outer-container" *ngIf="!formLoading">

<div id ="toolbar" *ngIf="!singleForm" nz-row>
  <div nz-col nzSpan="2">
    <img id="back-btn" src="../../../../assets/back.svg" (click)="onBackPressed()"/>
  </div>
  <div nz-col nzSpan="8" id="logo-container">
      <nz-avatar actionable= "false" nz-page-header-avatar nzShape="square" [nzSrc]="tenantLogoUrl">
      </nz-avatar>
  </div>
  <div nz-col nzSpan="6"></div>
  <div nz-col id="help" (click)="showHelp()" nzSpan="8">
      <a id="help-label">Help</a>
      <img id="help-icon" src="../../../../assets/help.svg" alt="profile"/>
  </div>
</div>
<div id="container">

  <nz-spin nzSimple [nzSpinning]="formLoading">
    <h2>{{ webForm?.title | dynamicTranslate : formId | async }}</h2>

    <nz-divider></nz-divider>

    <h3>{{ webForm?.sections[currentSection]?.title | dynamicTranslate | async }}</h3>

    <br />

    <app-question (status)="questionStatus = $event"
                  [question]="webForm?.sections[currentSection]?.questions[questionIndex]"
                  formViewType="SINGLE_QUESTION_VIEW" [sectionIndex]="currentSection" [questionIndex]="questionIndex"></app-question>

    <br />

    <nz-progress [nzPercent]="((currentQuestion + 1)/totalQuestions) * 100" [nzShowInfo]="false"
                [nzStrokeColor]="{ '0%': '#C20B50', '75%': '#85AE38', '100%': '#87d068' }" nzStatus="active"></nz-progress>
  </nz-spin>
</div>

<div class="actions">
  <button class="action-buttons" id="previous" nz-button nzType="primary" (click)="previous()">
    <em nz-icon nzType="step-backward" nzTheme="outline"></em>
    Previous
  </button>

  <button *ngIf="(currentQuestion + 1) !== totalQuestions" class="action-buttons" id="next" nz-button nzType="primary"
          (click)="next()" [disabled]="!questionStatus">
    Next
    <em nz-icon nzType="step-forward" nzTheme="outline"></em>
  </button>

  <button *ngIf="(currentQuestion + 1) === totalQuestions" class="action-buttons" id="submit" nz-button
          nzType="primary" (click)="submit()" [disabled]="!questionStatus" [nzLoading]="formSubmitting">
    Submit
    <em nz-icon nzType="check" nzTheme="outline"></em>
  </button>
</div>

</div>
