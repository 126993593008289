import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ResourceService } from '../resource.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicTranslationService {

  languages = ['en', 'es'];
  language: string;
  public populatedContextIds: Array<string>;
  public translationsMap: Map<string, string>;
  translatedResourcesKey: string;
  dynamicTranslationKey: string;

  constructor(private resourceService: ResourceService) {
    this.language = this.resourceService.getLanguage();
    this.translatedResourcesKey = "translated_resources";
    this.dynamicTranslationKey = "dynamic_translations_" + this.language;
    this.translationsMap = new Map();
    this.populatedContextIds = new Array();
  }

  initialize() {
      const translatedStrings = sessionStorage.getItem(this.dynamicTranslationKey);
      if (translatedStrings) {
        this.initializeTranslationsMap(translatedStrings);
        this.initializeTranslatedResources();
      }
  }

  initializeTranslationsMap(translatedStrings: string) {
    const languageJson = JSON.parse(translatedStrings);
    if (languageJson) {
      const keys = Object.keys(languageJson);
      keys.forEach((key) => {
        const value = languageJson[key];
        this.translationsMap.set(key, value);
      });
    }
  }

  initializeTranslatedResources() {
    const translatedContextIds = sessionStorage.getItem(this.translatedResourcesKey);
    if(translatedContextIds) {
      this.populatedContextIds = JSON.parse(translatedContextIds);
    }
  }


  getTranslation(key: string, contextId?: string): Observable<string> {
    let translatedStringSubject: Subject<string> = new BehaviorSubject<string>(key);
    if (contextId && this.populatedContextIds && this.populatedContextIds.indexOf(contextId) === -1) {
      this.resourceService.getTranslations(contextId, this.language).subscribe((translationResponse) => {
        let translationsResponseMap: Map<string, string> = new Map(Object.entries(translationResponse));
        if (translationsResponseMap) {
          let translatedStrings = JSON.parse(sessionStorage.getItem(this.dynamicTranslationKey)) || {};
          translationsResponseMap.forEach((value, key) => {
            this.translationsMap.set(key, value);
            translatedStrings[key] = value;
          });
          sessionStorage.setItem(this.dynamicTranslationKey, JSON.stringify(translatedStrings));
          const translatedContextIds = JSON.parse(sessionStorage.getItem(this.translatedResourcesKey)) || [];
          this.populatedContextIds.push(contextId);
          translatedContextIds.push(contextId);
          sessionStorage.setItem(this.translatedResourcesKey, JSON.stringify(translatedContextIds));
        }
        translatedStringSubject.next(this.translationsMap.has(key) ? this.translationsMap.get(key) : key);
      }, (err) => {
        console.log(err);
        translatedStringSubject.next(key);
      });
    } else {
      translatedStringSubject.next(this.translationsMap.has(key) ? this.translationsMap.get(key) : key);
    }
    return translatedStringSubject.pipe(first());
  }

}
