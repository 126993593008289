import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
	selector: 'app-sms-form-submitted',
	templateUrl: './sms-form-submitted.component.html',
	styleUrls: [ './sms-form-submitted.component.css' ]
})
export class SMSFormSubmittedComponent implements OnInit {
	jwtToken: string;
	nextActionItemId: string;
	nextFormBlueprintId: SVGStringList;
	formTitle: string;
	pendingForms: number;
	showMain = true;
	singleForm = false;
	
	constructor(
		private _route: ActivatedRoute,
		private _router: Router
		) {
	}
  
	ngOnInit(): void {
		this._route.queryParams.forEach((params: Params) => {
			this.jwtToken = params['jwtToken'];
			this.formTitle = params['formTitle'];
			this.pendingForms = params['pendingForms'];
			this.showMain = (params['showMain'] == 'true');
			this.singleForm = (params['singleForm'] == 'true');

			if(this.pendingForms>0){
				this.nextActionItemId = params['actionItemId'];
				this.nextFormBlueprintId = params['formId'];
			}
		});
	}
	
	goToMain(){
		this._router.navigate(['sms', this.jwtToken]);
	}

	goToNextForm(){
		this._router.navigate(['sms/form', this.nextActionItemId, this.nextFormBlueprintId, this.jwtToken]);
	}
}
