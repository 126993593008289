import { Component, Input, OnInit } from '@angular/core';
import { CellStructure } from '../../../../models/table-structure';
import { ICDCode } from '../../../icd-cpt-search/icd-code';

@Component({
  selector: 'app-cell-components',
  templateUrl: './cell-components.component.html',
  styleUrls: ['./cell-components.component.css']
})
export class CellComponentsComponent implements OnInit {

  @Input() cell: CellStructure;

  constructor() { }

  ngOnInit(): void {

  }

  onListChange(icdCodes: Array<ICDCode>) {
    if (icdCodes && icdCodes.length > 0){
      let icdCodeArray = new Array();
      icdCodes.forEach(icdCode => icdCodeArray.push(icdCode.id + " | " + icdCode.description))
      this.cell.value = icdCodeArray.join("<<|>>");
    }else
      this.cell.value = "";    
  }

}
