import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CreateResourceRequest } from './models/create-resource';
@Injectable({
  providedIn: 'root'
})
export class ResourceService {

	languages = [ 'en', 'es' ];
	endPoint: string;
	public rootUrl: string = environment.url;
   
	constructor(private _http: HttpClient) {
    this.endPoint = this.rootUrl + "/resources";
  }


	public getLanguage() : string {
		let language : string = 'en';
		if (this.languages.indexOf(window.navigator.language) > -1) language = window.navigator.language;
		return language;
	}

  create(createResourceRequest : CreateResourceRequest) {
    return this._http.post(this.endPoint, createResourceRequest);
  }

  getTranslations(contextId: string, locale: string) {
		return this._http.get(`${this.endPoint}/translations/${contextId}/locale/${locale}`);
	}

  getAllAvailableTranslations(contextId: string) : Observable<Map<string, Map<string, string>>> {
		return this._http.get<Map<string, Map<string, string>>>(`${this.endPoint}/translations/all-languages/${contextId}`);
  }
  
}
