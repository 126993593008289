import { NotFoundComponent } from './not-found/not-found.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SMSFormSubmittedComponent } from './internal/sms-form-submitted/sms-form-submitted.component';
import { FormExpiredComponent } from './internal/web-form/form-expired/form-expired.component';
import { NoPendingFormsComponent } from './internal/no-pending-forms/no-pending-forms.component';
import { SmsFormComponent } from './internal/web-form/sms-form/sms-form.component';
import { FormsLandingComponent } from './internal/web-form/forms-landing/forms-landing.component';
import { FormNotCompactableWithDeviceComponent } from './form-not-compactable-with-device/form-not-compactable-with-device.component';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: '/not-found'
	},{
		path: 'sms/:jwtToken',
		component: FormsLandingComponent
	},
	{
		path: 'not-found',
		component: NotFoundComponent
	},
	{
		path: 'sms/form/:actionItemId/:formBlueprintId/:jwtToken',
		component: SmsFormComponent
	},{
		path: 'form-submitted',
		component: SMSFormSubmittedComponent
	},{
		path: 'no-pending-forms',
		component: NoPendingFormsComponent
	},
	{
		path: 'form-expired',
		component: FormExpiredComponent
	},
	{
		path: 'web-form',
		loadChildren: () => import('./internal/internal.module').then((m) => m.InternalModule)
	},
	{
		path: 'sms-form',
		loadChildren: () => import('./internal/internal.module').then((m) => m.InternalModule)
	},
	{
		path: 'register',
		loadChildren: () => import('./registration/registration.module').then((m) => m.RegistrationModule)
	},
	{
		path: 'not-compatible',
		component: FormNotCompactableWithDeviceComponent
	},
	{
	  path:'**',
	  redirectTo: 'not-found'
	}
];

@NgModule({
	imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
	exports: [ RouterModule ]
})
export class AppRoutingModule {}
