<input #searchField *ngIf="isEditMode" nz-input [value]="selectedValue"
  [(ngModel)]="inputValue" (onSelect)="onCodeSelect($event)" (input)="onInput($event.target?.value)"
  [disabled]="!isEditMode" [nzAutocomplete]="auto" name="{{ searchType }}-search">
<nz-autocomplete nzBackfill (selectionChange)="onCodeSelect($event)" #auto>
  <nz-auto-option *ngFor="let option of options" [nzValue]="option">
   <ng-container *ngIf="showICDCode"> {{option[0]}} - </ng-container> {{option[1]}} - {{option[2]}} - {{option[3]}}
  </nz-auto-option>
</nz-autocomplete>

<ng-container *ngIf="searchType === 'icd'">
  <nz-list *ngIf="isListVisible" [nzDataSource]="dataList" nzBordered [nzHeader]="header" [nzRenderItem]="item"
    id="{{ serachType }}-search-list">
    <ng-template #item let-item>
      <nz-list-item>
        {{item.description}}
        <ul nz-list-item-actions>
          <nz-list-item-action>
            <i nz-icon nzType="delete" nzTheme="twotone" (click)="removeICDCode(item)"></i>
          </nz-list-item-action>
        </ul>
      </nz-list-item>
    </ng-template>
  </nz-list>
</ng-container>

<ng-container *ngIf="searchType === 'cpt'">
  <nz-list *ngIf="isListVisible" [nzDataSource]="dataList" nzBordered [nzHeader]="header" [nzRenderItem]="item"
    id="{{ serachType }}-search-list">
    <ng-template #item let-item>
      <nz-list-item>
        <nz-skeleton [nzActive]="true" [nzTitle]="false" [nzLoading]="icdListLoading">
          <nz-list-item-meta *ngIf="item.name" [nzTitle]="nzTitle" class="list-left" [nzDescription]="nzDescription">
            <ng-template #nzTitle>
              <strong class="list-left"> {{item.name}}</strong>
            </ng-template>
          </nz-list-item-meta>

          <nz-list-item-meta *ngIf="!item.name" [nzTitle]="nzTitle" class="list-left" [nzDescription]="nzDescription">
            <ng-template #nzTitle>
              <ng-container *ngFor="let cptCode of cptData">
                <strong *ngIf="cptCode.id === item.id" class="list-left"> {{ cptCode.name }} </strong>
              </ng-container>
            </ng-template>
          </nz-list-item-meta>
          <button nz-button nzType="danger" *ngIf="isEditMode" (click)="removeICDCode(item)" class="remove-item-button"
            id="{{ searchType }}-remove-btn-{{ item.id }}">
            remove
          </button>
        </nz-skeleton>
      </nz-list-item>
    </ng-template>
  </nz-list>
</ng-container>