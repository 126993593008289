
<div nz-row nzGutter="32" class="table_component">
    <div nz-col nzSpan="24" *ngIf="!loadingTable">
        <table>
            <tbody>
                <tr *ngFor="let data of question?.tableStructure?.table; index as rowIndex">
                    <td *ngFor="let datum of data; let columnIndex = index" [ngClass]="getClass(rowIndex, columnIndex)">
                        <span style="width: 100%;" *ngIf="!isHeader(rowIndex, columnIndex); else header">
                            <app-cell-components [cell]="question.tableStructure?.table[rowIndex][columnIndex]">
                            </app-cell-components>
                        </span>

                        <ng-template #header>
                                {{ question.tableStructure?.table[rowIndex][columnIndex].value }}
                        </ng-template>
                    </td>
                    <td class="action-column delete-class" *ngIf="question?.tableStructure?.horizontal">
                        <button *ngIf="rowIndex != 0" nz-button nzType="default" nzShape="circle" nzDanger (click)="deleteRow(rowIndex)">
                            <i nz-icon nzType="delete" nzTheme="fill"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
</div>

<div nz-row nzGutter="32" style="margin: 20px;" *ngIf="question?.tableStructure?.horizontal">
    <div nzSpan="4" nz-col nzOffset="19">
        <button nz-button nzType="primary" (click)="newRow()" > 
            <i nz-icon nzType="plus-circle" nzTheme="outline"></i> Add Row 
        </button>
    </div>
</div>