<div class="overall-container">
    <div >
        <div id="submitted">
            <img src="../../../assets/check-submitted.svg" alt="check" />
        </div>

        <h1 id="thank-you">Thanks, You have completed the {{formTitle}} form.</h1>

        <p *ngIf="!singleForm && pendingForms>0">You have {{pendingForms}} more pending assessments. Please click on “Next” to continue or go to the main page.</p>

        <br />
        <div *ngIf="!singleForm" class="center">
            <button id="btn-main"  *ngIf="showMain" (click)="goToMain()">Main Page</button>
            <button id="btn-next"  *ngIf="pendingForms>0" (click)="goToNextForm()">Next</button>
        </div>
    </div>
</div>