import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const authToken: string = localStorage.getItem('sessionToken') || '';
		const formId: string = localStorage.getItem('formid') || '';

		request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
		request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });

		if (authToken) request = request.clone({ headers: request.headers.set('x-auth-token', authToken) });
		if (formId) request = request.clone({ headers: request.headers.set('id', formId) });

		return next.handle(request);
	}
}
