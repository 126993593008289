<div class="overall-container">
    <div class="box">
        <div class="box__ghost">
          <div class="symbol"></div>
          <div class="symbol"></div>
          <div class="symbol"></div>
          <div class="symbol"></div>
          <div class="symbol"></div>
          <div class="symbol"></div>
          
          <div class="box__ghost-container">
            <div [ngStyle]="style" class="box__ghost-eyes">
              <div class="box__eye-left"></div>
              <div class="box__eye-right"></div>
            </div>
            <div class="box__ghost-bottom">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="box__ghost-shadow"></div>
        </div>
        
        <div class="box__description">
          <div class="box__description-container">
            <div class="box__description-title">Expired!</div>
            <div class="box__description-text">The Form has expired.<br> Please contact your Physician.</div>
          </div>
          
          
        </div>
        
      </div>
    </div>