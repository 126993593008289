<div nz-row nzGutter="64">
    <div nz-col nzSpan="24">
        <canvas #canvas width="400" height="300"></canvas>
        <p class="error-message" *ngIf="!isValid.sign"> Please add your sign </p>
    </div>
</div>

<div nz-row nzGutter="64">
    <div nz-col nzSpan="24" class="input-div">
        <label nz-typography nzType="secondary">Place of the signee<sup style="color: red; font-size: 12px;">*</sup></label>
        <input style="width: 405px;" nz-input placeholder="Place of the signee" [(ngModel)]="place"/>
        <p class="error-message error-message-input" *ngIf="!isValid.place"> Place of signee is required. </p>
    </div>
</div>

<div nz-row nzGutter="64">
    <div nz-col nzSpan="24" class="input-div">
        <label nz-typography nzType="secondary">Name of the signee <sup style="color: red; font-size: 12px;">*</sup></label>
        <input style="width: 405px;" nz-input placeholder="Name of the signee" [(ngModel)]="name" />
        <p class="error-message error-message-input" *ngIf="!isValid.name"> Name of signee is required. </p>
    </div>
</div>

<nz-divider></nz-divider>
<div nz-row nzGutter="64">
    <div nz-col nzSpan="12"> </div>
    <div nz-col nzSpan="4" style="margin: 5px;">
        <button nz-button nzType="danger" (click)="clear()">Clear</button>
    </div>
    <div nz-col nzSpan="4" style="margin: 5px;"> 
        <button nz-button nzType="primary" (click)="onSignatureSubmit()">Submit</button>
    </div>
</div>


