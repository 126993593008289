<div class="overall-container">
  <div class="box">
          <div id="submitted">
              <img src="../../../assets/form.svg" alt="check" />
          </div>

          <label id="thank-you">No Pending Forms</label>
          <br>
          <br>
          <p>You have no pending Forms tasks to be completed!</p>


      <br />

      <!-- <button nz-button nzType="primary" (click)="close()">Done</button> -->
  </div>
</div>