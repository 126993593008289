import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import SignaturePad from 'signature_pad';
import { FormsService } from 'src/app/internal/forms.service';
import { IpMetaData, Question, SignPad } from 'src/app/internal/models/webform';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.css']
})
export class SignatureComponent implements OnInit {

  @Input('question') question: Question;

  @ViewChild("canvas", { static: true }) canvas: ElementRef;

  signaturePad: SignaturePad;

  sign: string;
  place: string;
  name: string;
  signPad: SignPad = new SignPad();
  isValid: { sign: boolean, name: boolean, place: boolean } = {
    sign: true,
    name: true,
    place: true
  };

  constructor(
    private _modalRef: NzModalRef,
    private _formsService: FormsService
  ) { }

  ngOnInit(): void {
    this.signPad = new SignPad();
    this.signaturePad = new SignaturePad(this.canvas.nativeElement);
    this._formsService.getIPAddress().subscribe((res: IpMetaData) => {
        this.signPad.metaData = res;
    })
    
  }

  clear() {
    this.signaturePad.clear();
  }

  onSignatureSubmit(){

    if (!this.isFormValid())
      return;

    this.preview();
    this.signPad.signature = this.sign;
    this.signPad.dateTime = new Date().getTime();
    this.signPad.place = this.place;
    this.signPad.name = this.name;

    this.question.answer = [this.signPad]
    
    this._modalRef.close(true);
  }

  preview(){
    this.sign = this.signaturePad.toDataURL();
  }

  cancel(){
    this.clear();
    this._modalRef.close(false);
  }

  isFormValid(){

    this.isValid = {
      sign: true,
      name: true,
      place: true
    };

    if (this.name == undefined || this.name == null || this.name.length === 0 )
      this.isValid.name = false;

    if (this.place == undefined || this.place == null || this.place.length === 0)
      this.isValid.place = false;

    if (this.isCanvasBlank())
      this.isValid.sign = false;

    return (this.isValid.name && this.isValid.place && this.isValid.sign)

  }

  isCanvasBlank() {
    let canvas = this.canvas.nativeElement;
    const context = canvas.getContext('2d');
    const pixelBuffer = new Uint32Array(
      context.getImageData(0, 0, canvas.width, canvas.height).data.buffer
    );

    return !pixelBuffer.some(color => color !== 0);
  }

}
