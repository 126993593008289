export class TableStructure{

    title: string = "Table Title";
    description: string = "Table Description";
    horizontal: boolean = true;
    table: Array<Array<CellStructure>> = new Array();

    addExtraColumns: boolean = true;
    addExtraRows: boolean = true;

    constructor(){

        let rows: number = 3;
        let columns: number = 3;

        if(!this.horizontal){
            rows = 3;
            columns = 2;
        }

        for(let rowIndex: number = 0; rowIndex < rows; rowIndex++){
            this.table[rowIndex] = new Array();
            for(let columnIndex: number = 0; columnIndex < columns; columnIndex++){
                if (rowIndex == 0 && this.horizontal) {
                    var cell = new CellStructure();
                    cell.type = CellFieldType.TEXT;
                    cell.rows = 0;
                    cell.value = "Header " + (columnIndex + 1);
                    this.table[rowIndex][columnIndex] = cell;
                } else if (columnIndex == 0 && !this.horizontal) {
                    var cell = new CellStructure();
                    cell.type = CellFieldType.TEXT;
                    cell.rows = 0;
                    cell.value = "Header " + (rowIndex + 1);
                    this.table[rowIndex][columnIndex] = cell;
                } else {
                    var cell = new CellStructure();
                    cell.type = CellFieldType.TEXT;
                    cell.value = "";
                    cell.rows = 1;
                    this.table[rowIndex][columnIndex] = cell;
                }
            }
        }

    }

}

export class CellStructure {

    type: CellFieldType = CellFieldType.TEXT;
    rows: number = 0;
    value: any;
    options: Array<{ key: string, value: string }> = [];
    validators: Map<ValidatorType, any>;
}

export enum CellFieldType {
    TEXT = 'TEXT',
    TEXT_AREA = 'TEXT_AREA',
    NUMBER = 'NUMBER',
    DATE = 'DATE',
    DATE_TIME = 'DATE_TIME',
    DATE_RANGE = 'DATE_RANGE',
    CUSTOM_DROP_DOWN = 'CUSTOM_DROP_DOWN',
    ICD_DROP_DOWN = 'ICD_DROP_DOWN'
}

export enum ValidatorType {
    MIN,
    MAX,
    REQUIRED
}