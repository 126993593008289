import { Result } from './models/result';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateEvaluatedFormRequest } from './models/eval-form-request';
import { ActionItemDetails } from './models/action-item-details';
import { SMSFormResponse } from './models/sms-form-response';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class FormsService {
	rootURL: string = environment.url;

	constructor(private _http: HttpClient) { }

	fetchFormById(patientId: string): Observable<Result> {
		const endPoint: string = `${this.rootURL}/v1/filled-form?patientId=${patientId}`;

		return (this._http.get(endPoint) as unknown) as Observable<Result>;
	}

	fetchSMSFormById(formId: string, patientId?: string): Observable<Result> {
		let endPoint: string = `${this.rootURL}/v1/filled-form/sms/${formId}`;
		if(patientId)
			endPoint = `${endPoint}?patientId=${patientId}`

		return (this._http.get(endPoint) as unknown) as Observable<Result>;
	}

	fetchFormActionItemsForPatient(patientId: string): Observable<ActionItemDetails[]> {
		const endPoint: string = `${this.rootURL}/v2/actions/forms/${patientId}`;

		return (this._http.get(endPoint) as unknown) as Observable<ActionItemDetails[]>;
	}

	fetchActionItemDetails(actionItemId: string): Observable<ActionItemDetails> {
		const endPoint: string = `${this.rootURL}/v2/actions/${actionItemId}`;

		return (this._http.get(endPoint) as unknown) as Observable<ActionItemDetails>;
	}

	submitForm(evalFormRequest: CreateEvaluatedFormRequest) {
		const endPoint: string = `${this.rootURL}/v1/evalform`;

		return (this._http.post(endPoint, JSON.stringify(evalFormRequest)) as unknown) as Observable<SMSFormResponse>;
	}

	submitSMSForm(evalFormRequest: CreateEvaluatedFormRequest) {
		const endPoint: string = `${this.rootURL}/v1/evalSMSForm`;

		return (this._http.post(endPoint, JSON.stringify(evalFormRequest)) as unknown) as Observable<SMSFormResponse>;
	}

	submitDirectForm(evalFormRequest: CreateEvaluatedFormRequest) {
		const endPoint: string = `${this.rootURL}/v1/evalDirectForm`;

		return (this._http.post(endPoint, JSON.stringify(evalFormRequest)) as unknown) as Observable<SMSFormResponse>;
	}

	fetchActionItemById(actionId: string): Observable<ActionItemDetails> {
		const endPoint: string = `${this.rootURL}/v2/actions/${actionId}`;

		return (this._http.get(endPoint) as unknown) as Observable<ActionItemDetails>;
	}

	updateActionItem(actionItem: ActionItemDetails) {
		const endPoint: string = `${this.rootURL}/v2/actions/${actionItem?.id}`;

		return (this._http.put(endPoint, JSON.stringify(actionItem)) as unknown) as Observable<ActionItemDetails>;
	}

	public getIPAddress() {
		return this._http.get("https://api.ipify.org/?format=json");
	}
}
