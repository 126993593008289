import { TableStructure } from "./table-structure";
import { SignPad } from "./webform";

export interface Form {
	_id: string;

	title: string;

	type: FormType;

	schema: Schema;

	scoreCriteriaId: ScoringCriterias;

	formStandard: FormStandard;

	tenantId: { id: string };

	parentForm: string;
}

export interface ScoringCriterias {
	criterias: Criteria[];
}

export interface Criteria {
	startRange: number;

	endRange: number;

	identification: string;

	description: string;

	notify: string[];

	shouldSendAlert: boolean;
}

export interface Schema {
	title: string;

	description: string;

	bannerImage: string;

	scored: boolean;

	showResult: boolean;

	showResultTo: string[];

	tags: string[];

	type: FormType;

	elements: SectionElement[];

	formViewType: string;
}

export enum FormType {
	ASSESSMENT = 'ASSESSMENT',
	SCORED_ASSESSMENT = 'SCORED_ASSESSMENT',
	AGREEMENT = 'AGREEMENT',
	QUESTIONNAIRE = 'QUESTIONNAIRE'
}

export interface SectionElement {
	title: string;

	type: FieldElementTypes;

	helpText: string;

	imageUrl: string;

	displaySequence: number;

	scored: boolean;

	required: boolean;

	options: SectionOption[];

	answer: any[] | string | number | Date;

	elements: SectionElement[];

	resultConditions: ResultCondition[];

	tableStructure: TableStructure;

	signaturePad: SignPad;
}

export enum FieldElementTypes {
	RADIO = 'RADIO',
	CHECKBOX = 'CHECKBOX',
	INPUT_AREA = 'INPUT_AREA',
	INPUT = 'INPUT',
	DATE = 'DATE',
	SIGNATURE = 'SIGNATURE',
	TIME = 'TIME',
	UPLOAD = 'UPLOAD',
	PRE_FILLED = 'PRE_FILLED',
	ARITHMETIC = 'ARITHMETIC',
	TABLE = 'TABLE',
	PARAGRAPH = 'PARAGRAPH'
}

export interface SectionOption {
	option: string;

	answer: string | number;

	// Transient Field
	selected: boolean;

	type: OptionType;

	score: number;

	imageUrl: string;

	helpText: string;

	displaySequence: number;

	redirectToSection: number;

	validation: OptionValidation;

	arithmeticFieldType: ArithmeticFieldType;
}

export enum OptionType {
	RADIO = 'RADIO',
	CHECKBOX = 'CHECKBOX',
	RADIO_TEXT = 'RADIO_TEXT',
	INPUT = 'INPUT',
	DATE = 'DATE'
}

export interface OptionValidation {
	dataType: OptionValidationDataType;

	min: number;

	max: number;

	errorMessage: string;
}

export enum OptionValidationDataType {
	STRING = 'STRING',
	NUMBER = 'NUMBER',
	DATE = 'DATE'
}

export class ArithmeticFieldType {
	numbersUnit: string;

	arithmeticOperation: ArithmeticTypes;
}

export enum ArithmeticTypes {
	DIVIDE = 'DIVIDE',
	MULTIPLY = 'MULTIPLY',
	ADD = 'ADD',
	SUBTRACT = 'SUBTRACT'
}

export class ResultCondition {
	firstValue: number;

	secondValue: number;

	operation: ResultConditionOperation = ResultConditionOperation.BETWEEN;

	score: number;
}

export enum ResultConditionOperation {
	LESS_THAN = 'LESS_THAN',
	LESS_THAN_EQUAL_TO = 'LESS_THAN_EQUAL_TO',
	BETWEEN = 'BETWEEN',
	GREATER_THAN = 'GREATER_THAN',
	GREATER_THAN_EQUAL_TO = 'GREATER_THAN_EQUAL_TO',
	EQUAL_TO = 'EQUAL_TO',
	NOT_EQUAL_TO = 'NOT_EQUAL_TO'
}

export enum FormStandard {
	PHQ_9 = 'PHQ_9',
	GAD_7 = 'GAD_7',
	CUSTOM = 'CUSTOM',
	IHRS = 'IHRS',
	MARS = 'MARS',
	EMPTY = 'EMPTY',
	COVID = 'COVID',
	ABDQ = 'ABDQ',
	CSDD = 'CSDD'
}