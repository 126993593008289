import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  constructor() { }

  pageX: number;
  pageY: number;
  mouseY: number = 0;
  mouseX: number = 0;
  style: any;

  ngOnInit(): void {
    this.getScreenSize();
    window.addEventListener("mousemove", this.onMouseMove);
  }

  @HostListener('window:resize', ['$event'])
    getScreenSize() {
          this.pageY = window.innerHeight;
          this.pageX = window.innerWidth;
          console.log(this.pageY, this.pageX);
    }

  onMouseMove = (event?: MouseEvent) => {
    this.mouseY = event.pageY;
    let yAxis = (this.pageY/2- this.mouseY)/ this.pageY*300; 
  //horizontalAxis
    this.mouseX = event.pageX / - this.pageX;
    let xAxis = - this.mouseX * 100 - 100;

    this.style = { 'transform': 'translate('+ xAxis +'%,-'+ yAxis +'%)' }; 
  }

}
