<div nz-row [ngSwitch]="question?.option[0]?.prefilledFieldType">
    <div nz-col nzSpan="24" [ngClass]="class">
        <ng-container *ngSwitchCase="'DATE_OF_BIRTH'">
            <nz-date-picker nzFormat="mediumDate" [nzDisabledDate]="disabledDate" [nzMode]="'date'"
                [nzBorderless]="true" [(ngModel)]="question.answer[0]" (ngModelChange)="validateAnswer()">
            </nz-date-picker>
        </ng-container>

        <ng-container *ngSwitchCase="'GENDER'">
            <nz-radio-group [(ngModel)]="question.answer[0]" (ngModelChange)="validateAnswer()">
                <label nz-radio nzValue="MALE">Male</label>
                <label nz-radio nzValue="FEMALE">Female</label>
            </nz-radio-group>
        </ng-container>

        <ng-container *ngSwitchCase="'FULL_NAME'">
            <input nz-input nzBorderless [(ngModel)]="question.answer[0]" (ngModelChange)="validateAnswer()"/>
        </ng-container>

        <ng-container *ngSwitchCase="'FIRSTNAME'">
            <input nz-input nzBorderless [(ngModel)]="question.answer[0]" (ngModelChange)="validateAnswer()" />
        </ng-container>

        <ng-container *ngSwitchCase="'LASTNAME'">
            <input nz-input nzBorderless [(ngModel)]="question.answer[0]" (ngModelChange)="validateAnswer()" />
        </ng-container>

        <ng-container *ngSwitchCase="'MOBILE'">
            <input nz-input nzBorderless [(ngModel)]="question.answer[0]" (ngModelChange)="validateAnswer()" />
        </ng-container>

        <ng-container *ngSwitchCase="'EMAIL'">
            <input nz-input nzBorderless [(ngModel)]="question.answer[0]" (ngModelChange)="validateAnswer()" />
        </ng-container>

        <ng-container *ngSwitchCase="'ALLERGIES'">
            <input nz-input nzBorderless [(ngModel)]="question.answer[0]" (ngModelChange)="validateAnswer()" />
        </ng-container>

        <ng-container *ngSwitchDefault>
            <textarea [rows]="rows" nz-input [(ngModel)]="question.answer[0]" (ngModelChange)="validateAnswer()"
                [placeholder]="(textField?.option | dynamicTranslate | async) || 'Type your answer here...'"></textarea>
        </ng-container>
    </div>
</div>