<ng-container *ngFor="let option of question?.option">
    <div nz-row nzGutter="16">
        <div nz-col nzSpan="18" class="field-label">
            {{ option?.option  | dynamicTranslate | async }}
        </div>

        <div nz-col nzSpan="6">
            <nz-input-number [(ngModel)]="option.answer" (ngModelChange)="calculateResult()"></nz-input-number>
        </div>
    </div>

    <ng-container *ngIf="option?.answer < option?.validation?.min || option?.answer > option?.validation?.max">
        <nz-alert *ngIf="option?.answer !== null" nzType="error" nzMessage="Input not in range.">
        </nz-alert>
        <nz-alert nzType="error"
            nzMessage="{{ option?.option }} range: {{ option?.validation?.min }} - {{ option?.validation?.max }}">
        </nz-alert>
    </ng-container>

    <br />
</ng-container>

<br />

<nz-alert nzType="warning" nzMessage="Result {{ answer }}"></nz-alert>