<ng-container [ngSwitch]="cell?.type">

    <input *ngSwitchCase="'TEXT'" nzBorderless nz-input [(ngModel)]="cell.value" />

    <textarea *ngSwitchCase="'TEXT_AREA'" nzBorderless nz-input [rows]="rows" [(ngModel)]="cell.value">
    </textarea>

    <nz-input-number nzBorderless *ngSwitchCase="'NUMBER'" [(ngModel)]="cell.value">
    </nz-input-number>

    <nz-date-picker nzBorderless *ngSwitchCase="'DATE'" [(ngModel)]="cell.value"></nz-date-picker>

    <ng-container *ngSwitchCase="'ICD_DROP_DOWN'">
        <app-icd-cpt-search [isEditMode]="true" searchType="icd" (icdListChange)="onListChange($event)"></app-icd-cpt-search>
    </ng-container>

    <ng-container *ngSwitchCase="'CUSTOM_DROP_DOWN'">
        <nz-select [(ngModel)]="cell.value">
            <nz-option *ngFor="let option of cell.options" [nzValue]="option.key" [nzLabel]="option.value">

            </nz-option>
        </nz-select>
    </ng-container>

</ng-container>