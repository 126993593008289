import { SharedModule } from './shared/shared.module';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { NotFoundComponent } from './not-found/not-found.component';
import { RequestInterceptor } from './shared/request-interceptor';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ResourceModule } from './resources/resource.module';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';

registerLocaleData(en);

import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { FormNotCompactableWithDeviceComponent } from './form-not-compactable-with-device/form-not-compactable-with-device.component';

const ngZorroConfig: NzConfig = {
	message: { nzTop: 120 },
	notification: { nzTop: 240 }
};

@NgModule({
	declarations: [ AppComponent, NotFoundComponent, FormNotCompactableWithDeviceComponent ],
	imports: [ SharedModule, AppRoutingModule, BrowserModule, BrowserAnimationsModule, ResourceModule ],
	providers: [
		{ provide: NZ_CONFIG, useValue: ngZorroConfig },
		{ provide: NZ_I18N, useValue: en_US },
		{ provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
		{ provide: JWT_OPTIONS, useValue: JWT_OPTIONS },JwtHelperService
	],
	bootstrap: [ AppComponent ]
})
export class AppModule {}
