import { Injectable } from "@angular/core";
import { Criteria, FieldElementTypes, Form, FormStandard, ResultCondition, ResultConditionOperation, SectionElement, SectionOption } from "./models/form";
import { Question, Section, WebForm } from "./models/webform";

@Injectable({
    providedIn: 'root'
})
export class FormScoreCalculationService {

    calculate(originalForm: Form, webForm: WebForm): number {
        switch (originalForm.formStandard) {
            case FormStandard.ABDQ:
                return this.calculateABDQScore(originalForm.schema.elements, webForm);
            default:
                let evalutedScore: number = 0;

                const sections: SectionElement[] = originalForm.schema.elements;

                sections.forEach((element: SectionElement, sectionIndex: number) => {
                    const webFormSection: Section = webForm.sections[sectionIndex];

                    element.elements.forEach((questionElement: SectionElement, questionIndex: number) => {
                        const webFormQuestion: Question = webFormSection.questions[questionIndex];

                        evalutedScore = this.calculateQuestionScore(webFormQuestion, evalutedScore);

                        questionElement.answer = webFormQuestion.answer;
                    });
                });

                return evalutedScore;
        }
    }

    calculateABDQScore(sections: SectionElement[], webForm: WebForm) {
        const aSeries = [0, 2, 3, 5, 9, 10, 13, 14];
        const bSeries = [11, 12];
        const cSeries = [1, 4, 7];
        const dSeries = [8];
        const eSeries = [6];

        const answers = { a: 0, b: 0, c: 0, d: 0, e: 0 }

        sections.forEach((element: SectionElement, sectionIndex: number) => {
            const webFormSection: Section = webForm.sections[sectionIndex];

            element.elements.forEach((questionElement: SectionElement, questionIndex: number) => {
                const webFormQuestion: Question = webFormSection.questions[questionIndex];

                let evalutedScore: number = 0;

                const options: SectionOption[] = webFormQuestion.option.filter((o: SectionOption) => o.selected);
                if (options.length) evalutedScore += options[0].score;

                if (aSeries.includes(questionIndex)) answers['a'] += evalutedScore;
                else if (bSeries.includes(questionIndex)) answers['b'] += evalutedScore;
                else if (cSeries.includes(questionIndex)) answers['c'] += evalutedScore;
                else if (dSeries.includes(questionIndex)) answers['d'] += evalutedScore;
                else if (eSeries.includes(questionIndex)) answers['e'] += evalutedScore;

                questionElement.answer = webFormQuestion.answer;
            });
        });

        return (answers['a']) + (3 * answers['b']) + (4 * answers['c']) + (5 * answers['d']) + (6 * answers['e']);
    }

    calculateQuestionScore(webFormQuestion: Question, evalutedScore: number) {
        if (!webFormQuestion.answer.length) return evalutedScore;

        switch (webFormQuestion.type) {
            case FieldElementTypes.RADIO:
                const options: SectionOption[] = webFormQuestion.option.filter((o: SectionOption) => o.selected);
                if (options.length) evalutedScore += options[0].score;

                break;
            case FieldElementTypes.CHECKBOX:
                evalutedScore += webFormQuestion.option.reduce((o1, o2) => {
                    if (webFormQuestion.answer.includes(o2.option)) return o1 + o2.score;
                    return o1;
                }, 0);

                break;
            case FieldElementTypes.ARITHMETIC:
                const answer: number = webFormQuestion.answer[0];
                const score: number = this.identifyScoreRange(answer, webFormQuestion.resultConditions);
                if (null !== score && undefined !== score) evalutedScore += score;
                break;

            case FieldElementTypes.INPUT:
            case FieldElementTypes.INPUT_AREA:
            case FieldElementTypes.DATE:
            case FieldElementTypes.PRE_FILLED:
            default:
                evalutedScore += webFormQuestion.option[0].score;
                break;
        }

        return evalutedScore;
    }

    identifiedRisk(originalForm: Form, evalutedScore: number): { risk: string, alert: boolean } {
        let identifiedRisk: { risk: string, alert: boolean };

        originalForm.scoreCriteriaId.criterias.forEach((criteria: Criteria) => {
            if (identifiedRisk) return;

            if (criteria.startRange <= evalutedScore && evalutedScore <= criteria.endRange) {
                identifiedRisk = { risk: criteria.identification, alert: criteria.shouldSendAlert };
            }
        });

        originalForm.scoreCriteriaId.criterias.forEach((criteria: Criteria) => {
            if (identifiedRisk) return;

            if (evalutedScore < criteria.startRange) {
                identifiedRisk = { risk: criteria.identification, alert: criteria.shouldSendAlert };
            }
        });

        originalForm.scoreCriteriaId.criterias.forEach((criteria: Criteria) => {
            if (identifiedRisk) return;

            if (evalutedScore > criteria.endRange) {
                identifiedRisk = { risk: criteria.identification, alert: criteria.shouldSendAlert };
            }
        });

        return identifiedRisk;
    }

    identifyScoreRange(answer: number, resultConditions: ResultCondition[]): number {
        let score: number;

        resultConditions.forEach((condition: ResultCondition) => {
            switch (condition.operation) {
                case ResultConditionOperation.LESS_THAN:
                    if (answer < condition.firstValue) score = condition.score;
                    break;
                case ResultConditionOperation.LESS_THAN_EQUAL_TO:
                    if (answer <= condition.firstValue) score = condition.score;
                    break;
                case ResultConditionOperation.BETWEEN:
                    if (condition.firstValue < answer && answer < condition.secondValue) score = condition.score;
                    break;
                case ResultConditionOperation.GREATER_THAN:
                    if (answer > condition.firstValue) score = condition.score;
                    break;
                case ResultConditionOperation.GREATER_THAN_EQUAL_TO:
                    if (answer >= condition.firstValue) score = condition.score;
                    break;
                case ResultConditionOperation.EQUAL_TO:
                    if (answer === condition.firstValue) score = condition.score;
                    break;
                case ResultConditionOperation.NOT_EQUAL_TO:
                    if (answer !== condition.firstValue) score = condition.score;
                    break;
            }
        });

        return score;
    }
}