import { AnswerValidator } from '../answer-validator';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Question } from '../../../models/webform';

@Component({
	selector: 'app-text',
	templateUrl: './text.component.html',
	styleUrls: [ './text.component.css' ],
	providers: [ AnswerValidator ]
})
export class TextComponent implements OnInit, OnChanges {
	@Input('question') question: Question | undefined;
	@Input('formViewType') formViewType: string;

	textField: any;
	rows: number = 10;
	loading: boolean;
	parentClass: string = "single-question-view";

	constructor(private _answerValidator: AnswerValidator) {}


	ngOnInit(): void {
		this.loading = true;
		switch (this.formViewType) {
			case "SINGLE_PAGE_VIEW":
			case "SINGLE_SECTION_VIEW":
				this.rows = 1;
				this.parentClass = "single-page-view";
				break;
			default:
				this.rows = 10;
				this.parentClass = "single-question-view";
				break;
		}
		this.loading = false;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!this.question) return;

		if (changes.question.currentValue !== changes.question.previousValue) this.textField = this.question.option[0];
	}

	validateAnswer() {
		if (!this.question || !this.question.option[0]) return;

		this._answerValidator.validate(this.question.option[0], this.question);
	}
}
