import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class IcdCpcCodeService {
	endPoint: string;
	filteredOptions: any;

	constructor(private _http: HttpClient) {}

	getICDCodes(term: string) {
		this.endPoint =
			'https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&df=code,name&authenticity_token=&terms=' +
			term;

		return this._http.get(this.endPoint);
	}

	getCPCCodes(term: string) {
		this.endPoint =
			'https://clinicaltables.nlm.nih.gov/api/hcpcs/v3/search?sf=code,short_desc,long_desc&df=code,display&authenticity_token=&terms=' +
			term;

		return this._http.get(this.endPoint);
	}

	getCPTFromDB(term: string) {
		const otherCptArray = [
			[ 'None', 'None' ],
			[
				'99453',
				'Remote monitoring of physiologic parameter(s) (eg, weight, blood pressure, pulse oximetry, respiratory flow rate), initial; set-up and patient education on use of equipment',
				'RPM - Device Setup',
				21
			],
			[
				'99454',
				'Remote monitoring of physiologic parameter(s) (eg, weight, blood pressure, pulse oximetry, respiratory flow rate), initial; device(s) supply with daily recording(s) or programmed alert(s) transmission, each 30 days',
				'RPM - Vital Signs Monitoring',
				69
			],
			[
				'99457',
				'Remote physiologic monitoring treatment management services, 20 minutes or more of clinical staff/physician/other qualified healthcare professional time in a calendar month requiring interactive communication with the patient/caregiver during the month',
				'RPM - TMS Time',
				54
			],
			[
				'99458',
				'Remote physiologic monitoring treatment management services, 20 minutes or more of clinical staff/physician/other qualified healthcare professional time in a calendar month requiring interactive communication with the patient/caregiver during the month',
				'RPM - Additional TMS Time',
				39
			]
		];
		this.filteredOptions = otherCptArray.filter((option) => {
			const code = option[0] as string;
			const description = option[1] as string;
			if (
				code.toLowerCase().indexOf(term.toLowerCase()) === 0 ||
				description.toLowerCase().indexOf(term.toLowerCase()) === 0
			)
				return true;
			else return false;
		});

		return this.filteredOptions;
	}
}
