import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicTranslatePipe } from './pipes/dynamic-translate.pipe';
import { DynamicTranslationService } from "./services/dynamic-translation.service";

export function initializeDynamicTranslations(dynamicTranslationService: DynamicTranslationService) {
	return () => dynamicTranslationService.initialize();
}


@NgModule({
	imports: [CommonModule ],
	declarations: [ DynamicTranslatePipe ],
	exports: [ DynamicTranslatePipe ],
	providers: [
		DynamicTranslationService,
		{
			provide: APP_INITIALIZER,
			useFactory: initializeDynamicTranslations,
			deps: [ DynamicTranslationService ],
			multi: true
		}
	]
})
export class ResourceModule { 

}
