import { FieldElementTypes, FormType, ResultCondition, SectionOption } from './form';
import { TableStructure } from './table-structure';

export interface WebForm {
	title: string;

	type: FormType;

	sections: Section[];
}

export interface Section {
	title: string;

	questions: Question[];

	totalQuestions: number;
}

export class Question {
	title: string;

	type: FieldElementTypes;

	option: SectionOption[];

	required: boolean;

	scored: boolean = false;

	answer: any[] = [];

	sectionIndex: number;

	// Transient Field
	valid: boolean;

	helpText: string;

	resultConditions: ResultCondition[];

	tableStructure: TableStructure;

	signaturePad: SignPad;
}

export class SignPad {

	signature: any;

	dateTime: number;

	place: string;

	name: string;

	metaData: IpMetaData;
}

export class IpMetaData{
	ip: string;
	city: string;
	region: string;
	country: string;
	loc: string;
	org: string;
	postal: string;
	timezone: string;
}
