import { NzModalService } from 'ng-zorro-antd/modal';
import { FieldElementTypes } from './../../models/form';
import { Question } from './../../models/webform';
import { Component, Input, DoCheck, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { SignatureComponent } from '../answer/signature/signature.component';

@Component({
	selector: 'app-question',
	templateUrl: './question.component.html',
	styleUrls: [ './question.component.css' ]
})
export class QuestionComponent implements DoCheck, OnInit, AfterViewInit {

	@Input('question') question: Question | undefined;
	@Input('formViewType') formViewType: string;
	@Input('haveError') set haveError(haveError: string) {
		this.cardClass = (haveError) ? "single-page-view error-card" : "single-page-view";
	}
	@Input('sectionIndex') sectionIndex: number;
	@Input('questionIndex') questionIndex: number;

	@Output('status') status: EventEmitter<boolean> = new EventEmitter();
	cardClass: string = "single-question-view-card";
	titleClass: string = "single-question-title";
	loading: boolean = true;
	showUpdateButton: boolean = true;

	inputFields: Map<string, string> = new Map();

	constructor(
		private _modalService: NzModalService
	){}

	ngAfterViewInit(): void {
		if (this.question.type == "PARAGRAPH")
			this.replaceWithInputFields();
	}

	ngOnInit(): void {
		this.loading = true;
		switch (this.formViewType) {
			case "SINGLE_PAGE_VIEW":
				this.cardClass = "single-page-view";
				this.titleClass = "single-page-question-title";
				break;
			default:
				this.cardClass = "single-question-view-card";
				this.titleClass = "single-question-title";
		}
		this.loading = false;	
	}

	replaceWithInputFields() {

		const regex = /_+{{([^}]+)}}_+/g;  // Regular expression to match all occurrences inside {{}}

		const paragraph = this.question.answer[0];
		this.question.answer[1] = paragraph;

		const response = paragraph.replace(regex, function (match, p1, index) {
			const placeHolder = p1.replaceAll("-", " ");
			return `<input type="text" style="border-width: 0px 0px 1px 0px;" placeholder="${placeHolder}" id="input-${index}" data-match=${match} data-paragraph-input="1" />`;
		});

		setTimeout(() => {
			const paragraphContainerId = `paragraph-container-${this.sectionIndex}-${this.questionIndex}`;
			document.getElementById(paragraphContainerId).focus();
			document.getElementById(paragraphContainerId).innerHTML = response;

			let inputFields = document.getElementById(paragraphContainerId).querySelectorAll(`[data-paragraph-input="1"]`);

			inputFields.forEach((inputField: any) => {
				inputField.addEventListener("input", (event: any) => {
					if (event.target.value.length > 25){
						let element = document.getElementById(event.target.id);
						element.style.width = ((event.target.value.length * 4 ) + 200) + "px";
					}
				});
			})
		});	
	}
	
	ngDoCheck(): void {
		this.checkQuestionStatus();
	}

	checkQuestionStatus(): void {
		this.status.emit(false);

		if (!this.question) return;

		if (!this.question.required) {
			this.status.emit(true);
			return;
		}

		if (this.question.answer.length && FieldElementTypes.PRE_FILLED === this.question.type) {
			this.status.emit(true);
			return;
		}

		if (
			this.question.required &&
			this.question.answer !== undefined &&
			!!this.question.answer.toString().trim() &&
			this.question.valid
		)
			this.status.emit(true);
	}

	openCanvas(question){
		this._modalService.create({
			nzContent: SignatureComponent,
			nzComponentParams: {
				question: question
			},
			nzTitle: "Signature",
			nzMaskClosable: false,
			nzFooter: null
		})
	}

	update(){
		const paragraphContainerId = `paragraph-container-${this.sectionIndex}-${this.questionIndex}`;
		let inputFields = document.getElementById(paragraphContainerId).querySelectorAll(`[data-paragraph-input="1"]`);

		let questionString = this.question.answer[1];

		inputFields.forEach((inputField: any) => {
			const value = inputField.value;
			questionString = questionString.replace(inputField.dataset["match"], value);
		});

		this.question.answer[0] = questionString;
		document.getElementById(paragraphContainerId).innerHTML = questionString;
		this.showUpdateButton = false;
	}

	reset(){
		if (this.question.type == "PARAGRAPH"){
			this.question.answer[0] = this.question.answer[1];
			this.replaceWithInputFields();
			this.showUpdateButton = true;
		}
	}
}
