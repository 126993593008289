import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { HttpClientModule } from '@angular/common/http';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { CommonModule } from '@angular/common';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { IcdCptSearchComponent } from '../internal/web-form/icd-cpt-search/icd-cpt-search.component';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@NgModule({
  declarations: [IcdCptSearchComponent],
  imports: [
    NzCardModule,
    NzDividerModule,
    NzRadioModule,
    NzButtonModule,
    NzIconModule,
    NzProgressModule,
    NzCheckboxModule,
    NzModalModule,
    NzAlertModule,
    NzAvatarModule,
    NzDatePickerModule,
    NzGridModule,
    NzInputNumberModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    NzAutocompleteModule,
    NzSelectModule,
    NzListModule,
    NzSkeletonModule,
    NzInputModule,
    NzEmptyModule,
    NzNotificationModule,
    NzStepsModule,
    NzSpinModule,
  ],
  exports: [
    NzCardModule,
    NzDividerModule,
    NzRadioModule,
    NzButtonModule,
    NzIconModule,
    NzProgressModule,
    NzCheckboxModule,
    NzModalModule,
    NzAlertModule,
    NzAvatarModule,
    NzDatePickerModule,
    NzGridModule,
    NzInputNumberModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    NzAutocompleteModule,
    IcdCptSearchComponent,
    NzSelectModule,
    NzListModule,
    NzSkeletonModule,
    NzInputModule,
    NzEmptyModule,
    NzNotificationModule,
    NzStepsModule,
    NzSpinModule,
  ],
})
export class SharedModule {}
