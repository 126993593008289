<div class="overall-container">
  <div id ="toolbar" nz-row>
    <div nz-col nzSpan="8" id="logo-container">
        <nz-avatar actionable= "false" nz-page-header-avatar nzShape="square" [nzSrc]="tenantLogoUrl">
        </nz-avatar>
    </div>
    <div nz-col nzSpan="8"></div>
    <div nz-col id="help" (click)="showHelp()" nzSpan="8">
        <a id="help-label">Help</a>
        <img id="help-icon" src="../../../../assets/help.svg" alt="profile"/>
    </div>
  </div>
  <div id="forms">
      <h1 style="margin-bottom:0px">{{patientName}}'s Assessments</h1>
      <h3>Click on any link below to complete your assessments</h3>
      <ng-container id="forms-list" *ngFor="let formActionItem of formActionItems">
        <nz-card id="form-item" [nzHoverable]="true" [nzBodyStyle]="{ height: '50px', 'padding':'10px', 'text-align': 'center' }" (click)="!formActionItem.completed ? selectForm(formActionItem) : completedForm()" >
          <div id="form-title">
            <h2 class="form-title" [style.color]="formActionItem.completed ? 'gray' : '#85AE38'">{{formActionItem.title}}</h2>
          </div>
          <div id="form-logo">
            <img *ngIf="formActionItem.completed" src="../../../../assets/check-circle-outline.svg" alt="check" />
            <img *ngIf="!formActionItem.completed" src="../../../../assets/chevron-right-circle.svg" alt="un-checked"/>
          </div>
        </nz-card>
      </ng-container>
  </div>
</div>