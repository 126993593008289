import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { FormsService } from '../../forms.service';
import { ActionItemDetails } from '../../models/action-item-details';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FormHelpComponent } from '../form-help/form-help.component';

@Component({
  selector: 'app-forms-landing',
  templateUrl: './forms-landing.component.html',
  styleUrls: ['./forms-landing.component.css']
})
export class FormsLandingComponent implements OnInit {
  	patientId: string;
	tenantId: string;
	jwtToken: string;
	patientName: string;
	tenantLogoUrl: string;
	formActionItems: ActionItemDetails[];

  	constructor(
		private _formsService: FormsService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _jwtHelper: JwtHelperService,
		private _modalService: NzModalService
	) {
	}

	ngOnInit(): void {
		this._route.params.forEach((params: Params) => {
			this.jwtToken = params['jwtToken'];
			let decodedToken = this._jwtHelper.decodeToken(this.jwtToken);
			let expirationDate = this._jwtHelper.getTokenExpirationDate(this.jwtToken);
			let isExpired = this._jwtHelper.isTokenExpired(this.jwtToken);
			console.log("Decoded Token", decodedToken);
			console.log("expirationDate", expirationDate);
			console.log("isExpired", isExpired);
			if(isExpired) {
				this._router.navigate(['form-expired']);
			}else{
				this.patientId = decodedToken['patientId'];
				this.tenantId = decodedToken['tenantId'];
				this.patientName = decodedToken['patientName'];
				this.tenantLogoUrl = decodedToken['tenantLogoUrl'];
				console.log("Patient "+this.patientId)
				this.getActionItemsForPatient();
			}
		
		});
	}

  getActionItemsForPatient(){
		this._formsService.fetchFormActionItemsForPatient(this.patientId).subscribe((result: ActionItemDetails[]) => {
			// console.log("Form Action Items "+result.length);
			if(result.length == 0){
				this._router.navigate([ 'no-pending-forms' ]);
			}else{
				this.formActionItems = result;
			}
		})
	}

	selectForm(formActionItem: ActionItemDetails){
		var formParameters: Map<string, string> = new Map(Object.entries(JSON.parse(formActionItem.parameters)));
		var formActionItemId = formActionItem.id;
		var formBlueprintId: string = formParameters.get("formBlueprintId");
		console.log("Action Item Id "+formActionItemId+" Form Id "+formBlueprintId);
		this._router.navigate(['sms/form', formActionItemId, formBlueprintId, this.jwtToken]);
	}

	completedForm(){
		this._modalService.info({
			nzIconType: null,
			nzContent: 'You have already completed this Assessment.',
			nzOnOk: () => {
				close;
			},
			nzClosable: false,
			nzMaskClosable: false
		});
	}

	showHelp(){
		this._modalService.create({
			nzContent: FormHelpComponent,
			nzComponentParams: {
			},
			nzWidth: "80%",
			nzClosable: true,
			nzMaskClosable: true,
			nzCloseIcon:"./../../../assets/close",
			nzFooter: null
		});
	}

}
