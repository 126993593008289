import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Question } from '../../../models/webform';
import { AnswerValidator } from '../answer-validator';

@Component({
	selector: 'app-prefilled',
	templateUrl: './prefilled.component.html',
	styleUrls: [ './prefilled.component.css' ],
	providers: [ AnswerValidator ]
})
export class PrefilledComponent implements OnInit, OnChanges {
	@Input('question') question: Question | undefined;
	@Input('formViewType') formViewType: string;

	textField: any;

	disabledDate = (current: Date): boolean => {
		return differenceInCalendarDays(new Date(), current) < 0;
	};

	constructor(private _answerValidator: AnswerValidator) {}

	loading: boolean;
	class: string = "single-question-view";

	ngOnInit(): void {
		this.loading = true;
		switch (this.formViewType) {
			case "SINGLE_PAGE_VIEW":
			case "SINGLE_SECTION_VIEW":
				this.class = "single-page-view-class";
				break;
			default:
				this.class = "single-question-view";
		}
		this.loading = false;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!this.question) return;

		if (changes.question.currentValue !== changes.question.previousValue) this.textField = this.question.option[0];
	}

	validateAnswer() {
		if (!this.question || !this.question.option[0]) return;

		this._answerValidator.validate(this.question.option[0], this.question);
	}
}
