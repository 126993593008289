import { Component, Input } from '@angular/core';
import { Question } from '../../../models/webform';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

@Component({
	selector: 'app-date',
	templateUrl: './date.component.html',
	styleUrls: [ './date.component.css' ]
})
export class DateComponent {
	@Input('question') question: Question | undefined;
	@Input('formViewType') formViewType: string;

	disabledDate = (current: Date): boolean => {
		return (
			differenceInCalendarDays(new Date(this.question.option[0].validation.min), current) > 0 ||
			differenceInCalendarDays(new Date(this.question.option[0].validation.max), current) < 0
		);
	};

	validateAnswer() {
		if (!this.question || !this.question.option[0]) return;

		if (!!this.question.answer) this.question.valid = true;
		else this.question.valid = false;
	}
}
