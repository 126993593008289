import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { DynamicTranslationService } from "../services/dynamic-translation.service";


//Make sure you call atleast one instance of the pipe with contextId passed in a component. eg : {{ title | dynamicTranslate : contextId }}
@Pipe({
  name: 'dynamicTranslate'
})
export class DynamicTranslatePipe implements PipeTransform {


  constructor(private dynamicTranslationService: DynamicTranslationService) {

  }

  transform(key: string, contextId?: string): Observable<string> {
    return this.dynamicTranslationService.getTranslation(key, contextId); 
  }

}
