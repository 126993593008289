import { Injectable } from '@angular/core';
import { SectionOption, OptionValidationDataType } from '../../models/form';
import { Question } from '../../models/webform';

@Injectable()
export class AnswerValidator {
	validate(option: SectionOption, question: Question): void {
		if (!option.validation) {
			question.valid = true;
			return;
		}

		switch (option.validation.dataType) {
			case OptionValidationDataType.STRING:
				this.validateString(option, question);
				break;
			case OptionValidationDataType.NUMBER:
				this.validateNumber(option, question);
				break;
			default:
				false;
		}
	}

	validateString(option: SectionOption, question: Question) {
		if (!question.answer.toString().trim()) return;

		if (question.answer.length < option.validation.min || question.answer.length > option.validation.max)
			question.valid = false;
		else question.valid = true;
	}

	validateNumber(option: SectionOption, question: Question) {
		if (!parseInt(`${question.answer}`)) return;

		if (+question.answer < option.validation.min || +question.answer > option.validation.max)
			question.valid = false;
		else question.valid = true;
	}
}
