import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { SectionOption } from '../../../models/form';
import { Question } from '../../../models/webform';
import { DynamicTranslatePipe } from '../../../../../app/resources/pipes/dynamic-translate.pipe';

@Component({
	selector: 'app-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: [ './checkbox.component.css' ],
	providers: [ DynamicTranslatePipe ]
})
export class CheckboxComponent implements OnInit, OnChanges {
	@Input('question') question: Question | undefined;
	@Input('formViewType') formViewType: string;

	checkBoxOptions: any[] = [];
	loading: boolean;
	checkBoxClass = "single-question-view";

	constructor(private _dynamicTranslatePipe: DynamicTranslatePipe) {

	}

	ngOnInit(): void {
		this.loading = true;
		switch (this.formViewType) {
			case "SINGLE_PAGE_VIEW":
			case "SINGLE_SECTION_VIEW":
				this.checkBoxClass = "single-page-view";
				break;
			default:
				this.checkBoxClass = "single-question-view";
				break;
		}
		this.loading = false;
	}

	
	ngOnChanges(): void {
		if (!this.question) return;

		this.checkBoxOptions = [];

		this.question.option.forEach((questionOption: SectionOption) => {
			if (!this.question) return;
			let checkboxLabel : string= questionOption.option;
			this._dynamicTranslatePipe.transform(questionOption.option).subscribe((value) => checkboxLabel = value);

			let answer: boolean = false;

			if (this.question.answer)
				answer = !!this.question.answer.find((ans: string) => questionOption.option === ans);

			const checkBox = {
				label: checkboxLabel,
				value: questionOption.option,
				checked: answer
			};
			this.checkBoxOptions.push(checkBox);
		});
	}

	setAnswer() {
		if (!this.question) return;

		const answers: string[] = [];

		this.checkBoxOptions.forEach((option: any) => {
			if (option.checked) answers.push(option.value);
		});

		this.question.answer = answers;
		if (this.question.answer.length) this.question.valid = true;
	}
}
