<nz-radio-group *ngIf="!loading" class="single-page-view" [(ngModel)]="question.answer[0]" (ngModelChange)="validateAnswer(); markOption($event);">
    <ng-container *ngFor="let option of question?.option">
        <ng-container *ngIf="'RADIO_TEXT' !== option?.type && !loading">
            <label [ngClass]="radioClass" nz-radio [nzValue]="option?.option">{{ option?.option | dynamicTranslate | async  }}</label>
        </ng-container>

        <ng-container *ngIf="'RADIO_TEXT' === option?.type && !loading">
            <label>
                <input class="single-page-view" [placeholder]="option?.option" [(ngModel)]="question.answer[0]" nz-input
                    (ngModelChange)="validateAnswer();markOption($event)" class="text-field" />
            </label>
        </ng-container>
    </ng-container>
</nz-radio-group>