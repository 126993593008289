import { Component,OnInit } from '@angular/core';

@Component({
  selector: 'app-form-help',
  templateUrl: './form-help.component.html',
  styleUrls: ['./form-help.component.css']
})
export class FormHelpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }


}
