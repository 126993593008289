import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ICDCode as Code } from './icd-code';
import { IcdCpcCodeService } from './icd-cpt-search.service';

@Component({
  selector: 'app-icd-cpt-search',
  templateUrl: './icd-cpt-search.component.html',
  styleUrls: ['./icd-cpt-search.component.scss']
})
export class IcdCptSearchComponent implements OnInit {

  cpt = 'cpt';
  icd = 'icd';
  newCptCodes = [
		{
			id: '99453',
			name: 'RPM - Device Setup',
			description:
        'Remote monitoring of physiologic parameter(s) (eg, weight, blood pressure, pulse oximetry, respiratory flow rate), initial; set-up and patient education on use of equipment',
      category: '',
			billRate: 21
		},
		{
			id: '99454',
			name: 'RPM - Vital Signs Monitoring',
			description:
        'Remote monitoring of physiologic parameter(s) (eg, weight, blood pressure, pulse oximetry, respiratory flow rate), initial; device(s) supply with daily recording(s) or programmed alert(s) transmission, each 30 days',
      category: '',
			billRate: 69
		},
		{
			id: '99457',
			name: 'RPM - TMS Time',
			description:
        'Remote physiologic monitoring treatment management services, 20 minutes or more of clinical staff/physician/other qualified healthcare professional time in a calendar month requiring interactive communication with the patient/caregiver during the month',
      category: '',
			billRate: 54
		},
		{
			id: '99458',
			name: 'RPM - Additional TMS Time',
			description:
        'Remote physiologic monitoring treatment management services, 20 minutes or more of clinical staff/physician/other qualified healthcare professional time in a calendar month requiring interactive communication with the patient/caregiver during the month',
      category: '',
			billRate: 39
		}
	];

    otherCptArray = [['None', 'None'],
                    ['99453', 'Remote monitoring of physiologic parameter(s) (eg, weight, blood pressure, pulse oximetry, respiratory flow rate), initial; set-up and patient education on use of equipment', 'RPM - Device Setup', 21],
                    ['99454', 'Remote monitoring of physiologic parameter(s) (eg, weight, blood pressure, pulse oximetry, respiratory flow rate), initial; device(s) supply with daily recording(s) or programmed alert(s) transmission, each 30 days', 'RPM - Vital Signs Monitoring', 69],
                    ['99457', 'Remote physiologic monitoring treatment management services, 20 minutes or more of clinical staff/physician/other qualified healthcare professional time in a calendar month requiring interactive communication with the patient/caregiver during the month', 'RPM - TMS Time', 54],
                    ['99458', 'Remote physiologic monitoring treatment management services, 20 minutes or more of clinical staff/physician/other qualified healthcare professional time in a calendar month requiring interactive communication with the patient/caregiver during the month', 'RPM - Additional TMS Time', 39]];
  @Input('searchType') searchType: string = 'icd';
  @Input('isListVisible') isListVisible = true;
  @Input('icdList') icdList = [];
  @Input('cptList') cptList = ['99453', '99454', '99457', '99458'];
  @Input('isEditMode') isEditMode = true;
  @Input('dataList') dataList = [];
  @Input('header') header = '';
  @Output('icdListChange') icdListChange: EventEmitter<Code[]> = new EventEmitter();
  @Output('cptListChange') cptListChange: EventEmitter<Code[]> = new EventEmitter();
  icdData: Array<Code> = [];
  cptData: Array<Code> = this.newCptCodes;
  @Input('icdDataList') icdDataList: Array<any> = new Array();
  @Input('cptDataList') cptDataList: Array<any> = new Array();
  @Input() showICDCode: boolean = false;
  options: Array<string[]> = [];
  codes: string[] = [];
  icdListLoading = false;
  inputValue;
  selectedValue;
  value;

  constructor(private icdCpcCodeService: IcdCpcCodeService) {
    // this.searchType = this.icd;
   }

  ngOnInit() {
    switch (this.searchType) {
      case 'icd':
        this.dataList = [...this.icdDataList];
        break;
      case 'cpt':
        this.dataList = [...this.cptDataList];
        break;
    }
  }

  onInput(value: string): void {
    this.options = new Array();
    switch (this.searchType) {
      case 'icd':
        this.icdCpcCodeService.getICDCodes(value).subscribe((res: any) => {
          if (res && res.length > 0) {
            this.options = res[3];
          }
        });
        break;
      case 'cpt':
        this.options = this.icdCpcCodeService.getCPTFromDB(value);
        this.icdCpcCodeService.getCPCCodes(value).subscribe((res: any) => {
          if (res && res.length > 0) {
            this.options = this.options.concat(res[3]);
          }
        });
        break;
    }

  }

  onCodeSelect(event: any): void {
    if (event.selected) {
      const codeArray = [...event.nzValue];
      const icdCode = new Code();
      icdCode.id = codeArray[0];
      icdCode.description = codeArray[1];
      event.nzValue = '';
      if (this.searchType == 'icd') {
        this.icdDataList.push(icdCode);
        this.dataList = [...this.icdDataList];
        this.icdListChange.emit(this.icdDataList);
      } else if (this.searchType == 'cpt') {
        if (icdCode.id == 'None')
          this.cptDataList = [];
        else {
          icdCode.name = codeArray[2];
          icdCode.billRate = codeArray[3];
          this.cptDataList = this.cptDataList.filter(eachCpt => eachCpt.id != 'None');
        }

        if (!this.cptDataList.find(cpt => cpt.id === icdCode.id)) {
          this.cptDataList.push(icdCode);
          this.dataList = [...this.cptDataList];
        }
        this.cptListChange.emit(this.cptDataList);
      }
    }
  }

  removeICDCode(valueToBeRemoved: any): void {
    let filteredIcd = [];
    let filteredCpt = [];
    this.dataList = [];
    if (this.searchType == 'icd') {
      filteredIcd = this.icdDataList.filter(eachIcd => valueToBeRemoved.id != eachIcd.id);
      this.dataList = filteredIcd;
      this.icdDataList = filteredIcd;
      this.icdListChange.emit(this.icdDataList);
    } else if (this.searchType == 'cpt') {
      filteredCpt = this.cptDataList.filter(eachIcd => valueToBeRemoved.id != eachIcd.id);
      this.dataList = [...filteredCpt];
      this.cptDataList = [...this.dataList];
      this.cptListChange.emit(this.cptDataList);
    }
  }

}
