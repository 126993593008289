import { Component, Input, OnInit } from '@angular/core';
import { SectionOption } from '../../../models/form';
import { Question } from '../../../models/webform';

@Component({
	selector: 'app-radio',
	templateUrl: './radio.component.html',
	styleUrls: [ './radio.component.css' ]
})
export class RadioComponent implements OnInit{

	@Input('question') question: Question | undefined;
	@Input('formViewType') formViewType: string;

	optionClass: string = "single-question-view";
	loading: boolean;
	radioClass: string = "single-question-view";

	ngOnInit(): void {
		this.loading = true;
		switch (this.formViewType) {
			case "SINGLE_PAGE_VIEW":
			case "SINGLE_SECTION_VIEW":
				this.optionClass = "single-page-view";
				this.radioClass = "single-page-view";
				break;
			default:
				this.optionClass = "single-question-view";
				this.radioClass = "single-question-view";
				break;
		}
		this.loading = false;
	}

	validateAnswer() {
		if (!this.question || !this.question.option[0]) return;

		if (this.question.answer.length) this.question.valid = true;
	}

	markOption(option: string) {
		this.question.option.forEach((o: SectionOption) => {
			if (o.option === option) {
				o.selected = true;
				return;
			}
			o.selected = false;
		});
	}
}
